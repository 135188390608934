<template>
  <div class="dataElementDetails" v-loading="loading">
    <div class="l-start tableHeaderTips" v-if="rowData.dataType == 2">
          <!--  --> 
          <div>文件夹数(个) : {{ datas.extraData?datas.extraData.dirCount:'' }}</div>
          <div>文件夹(个) : {{ datas.extraData?datas.extraData.fileCount:'' }}</div>
        </div>
    <el-table
      :data="list"
      ref="el_TableRef"
      border
      highlight-current-row
      row-key="schemaId"
      style="width: 100%"
      max-height="528px"
    
    >
      <el-table-column type="index" width="80" label="序号" align="center">
        <template slot-scope="{ $index }">
          <span>{{ (currentPage - 1) * pageSize + $index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="item in fieldsList"
        :key="item.prop"
        :label="item.label"
        :width="item.width || '267px'"
      >
        <template slot-scope="{ row }">
          <span>{{ removeSchemaPrefix(row[item.prop],item.prop) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        :current-page="currentPage"
        :page-sizes.sync="pageSizes"
        :page-size.sync="pageSize"
        :total="total"
        class="p-bar"
        background
        layout="total,prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :disabled="loading"
      />
    </div>
  </div>
</template>
<script>
import TableMixin from "@/mixins/table";
export default {
  name: "DataElementDetails",
  mixins: [TableMixin],
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    },
    baseInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {
        tableTop: 550
      },
      listApiName: "dataPresentation2",
      fieldsList: [
      ],
      fieldsList2:[
        { label: "数据表名称", prop: "assetName" },
        { label: "原始字段名称", prop: "srcSchema" },
        { label: "数据字段名称", prop: "schemaName" },
        { label: "中文注释", prop: "schemaCn", width: "266px" },
        
      ],
      fieldsList3:[
        { prop: "path", label: "文件目录信息", width: "auto" },
        { prop: "size", label: "大小(B)", width: "auto" }
      ],
      pageSize: 100,
      loading: false,
      search: {
        dataId: ""
      },
      datas:{},
      rowd:this.baseInfo
    };
  },
  watch:{
    'show'(v){
      if(v){
        if(this.rowData.dataType == 2){
          this.fieldsList = JSON.parse(JSON.stringify(this.fieldsList3))

      }else{
        this.fieldsList = JSON.parse(JSON.stringify(this.fieldsList2))
      }
      }
     
    },
    deep:true

    
  },

  mounted() {
   if(this.rowData.dataType == 2){
      this.fieldsList = JSON.parse(JSON.stringify(this.fieldsList3))

      }else{
        this.fieldsList = JSON.parse(JSON.stringify(this.fieldsList2))
      }
    this.search.dataId = this.baseInfo.dataId;
    this.getList(this.callback);
  
  },
  methods: {
    callback(v){
      this.datas = v.data
    },
    removeSchemaPrefix(str,prop) {
      if(prop!="schemaName" ){
        return str
      }
      // 确保 str 是一个字符串
      if (typeof str !== "string") {
        return str; // 或者抛出错误，取决于你的需求
      }

      // 检查字符串是否以 "schema://" 开头
      if (str.startsWith("schema://")) {
        // 移除 "schema://" 部分
        return str.substring("schema://".length);
      }

      // 如果不是，则直接返回原字符串
      return str;
    }
  }
};
</script>
<style lang="less" scoped>
.dataElementDetails {
}
.tableHeaderTips {
  font-size: 14px;
  div {
    margin-right: 20px;
  }
}
</style>
