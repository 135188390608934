import {
  queryDataShowList,
  getDataElementDetails,
  getDataElementQualityReporting__SummaryTable,
  getDataElementQualityReporting__FullList
} from "@/api/dataPresentation";
export default {
  components: {},

  data() {
    return {
      loading: false, // 加载动画
      list: [],
      fieldList: [],
      tableHeight: window.innerHeight - 340,
      currentPage: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      total: 0,
      search: {}, // 搜索内容
      initParams: {},
      scrollToTopAfterGetList: true
    };
  },

  computed: {},
  watch: {},
  /**
   * 增加高级筛选逻辑
   * 增加 props
   * showScene  控制场景是否展示
   * showSearch 控制搜索是否展示
   * request 自定义请求
   * params 自定义参数
   */
  created() {
    this.updateTableHeight();
    window.addEventListener("resize", this.updateTableHeight);
  },
  mounted() {
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateTableHeight);
  },

  methods: {
    /**
     * 获取列表请求
     */
    getListApi() {
      return {
        dataPresentation1: queryDataShowList,
        dataPresentation2: getDataElementDetails,
        dataPresentation3: getDataElementQualityReporting__SummaryTable,
        dataPresentation4: getDataElementQualityReporting__FullList
      }[this.listApiName];
    },

    /**
     * 获取列表数据
     */
    async getList(callback) {
      this.loading = true;
      var crmIndexRequest = this.getListApi();
      var params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        ...this.search
      };
      try {
        let res = await crmIndexRequest(params);

        if (callback) callback(res);
        setTimeout(() => {
          this.loading = false;
        }, 500);
        if (res.data.totalRow && Math.ceil(res.data.totalRow / this.pageSize) < this.currentPage && this.currentPage > 1) {
          this.currentPage = Math.ceil(res.data.totalRow / this.pageSize);
          this.getList();
        } else {
          this.total = res.data.totalRow;
          this.list = res.data.list;
          this.scrollToTopAfterGetList && window.scrollTo(0, 0, "smooth");
          this.$nextTick(() => {
            this.$refs.el_TableRef && this.$refs.el_TableRef.bodyWrapper.scrollTo(0, 0, "smooth");
          });
        }
      } catch (error) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    /**
     * 更新表高
     */
    updateTableHeight() {
      if (this.config && this.config.tableTop) {
        this.tableHeight = window.innerHeight - this.config.tableTop;
      } else {
        return window.innerHeight - 500;
      }
    },
    /**
     * 更改每页展示数量
     * @param {*} val
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },

    /**
     * 更改当前页数
     * @param {*} val
     */
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    }
  }
};
