<template>
  <div class="header-wrap" :class="{ headcls: headcls }">
    <div class="header l-between">
      <div class="logo-wrap" @click="navHandle({ url: '/', title: '首页' })">
        <div class="logo l-start">
          <img :src="globalConfig.headerLogo" style="height: 50px; width: auto" alt="" />
          <!-- <img src="http://192.168.6.230/public/suZhouHeaderLogo.png" style="height: 40px;width: auto;" alt="" /> -->
          <div class="xian" v-if="false">|</div>
          <div class="txtbox" v-if="false">
            <el-select v-model="value" @change="seleChange" filterable placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
          <!-- <img src="../../assets/images/logo.png" alt="" v-if='!headcls'/> -->
        </div>
        <!-- <div class="logo-line"></div>
        <span>专注工业智能化</span> -->
      </div>

      <div class="l-center">
        <div class="nav-wrap">
          <div class="nav-item">
            <span
              class="nav-name"
              :class="{ active: routePath.indexOf('home') > -1 }"
              @click="navHandle({ url: '/', title: '首页' })"
              >首页</span
            >
          </div>
          <div class="nav-item nav-itemfw">
            <span
              class="nav-name"
              :class="{ active: routePath.indexOf('flow2') > -1 }"
              @click="navHandle({ url: '/flow2', title: '登记申请' })"
              >登记申请</span
            >
          </div>

          <div class="nav-item nav-itemfw">
            <span
              class="nav-name"
              :class="{ active: routePath.indexOf('service') > -1 }"
              @click="navHandle({ url: '/service', title: '数据展示' })"
              >数据展示</span
            >
          </div>
          <!-- <div class="nav-item nav-itemfw">
            <span
              class="nav-name"
              :class="{ active: routePath.indexOf('news') > -1 }"
              @click="navHandle({ url: '/news', title: '新闻资讯' })"
              >新闻资讯</span
            >
          </div> -->
          <div class="nav-item">
            <span
              class="nav-name"
              :class="{ active: routePath.indexOf('about') > -1 }"
              @click="navHandle({ url: '/about', title: '关于我们' })"
              >关于我们</span
            >
          </div>
        </div>
      </div>

      <div class="rigbox l-start">
        <img class="himg b" v-if="!ismsg && istoken" @click="msgFn" src="@/assets/img/hh1.png" alt="" />
        <img class="himg b" v-if="ismsg > 0 && istoken" @click="msgFn" src="@/assets/img/hh2.png" alt="" />
        <div class="" v-if="!loginUserInfo.mobile" style="width: 180px">
          <el-button class="hbtn" type="primary" round @click="goHref('登录')">登录</el-button>
          <el-button class="hbtn" round @click="goHref('注册')">注册</el-button>
        </div>
        <div class="use l-start" v-if="loginUserInfo.mobile">
          <div class="ubox">
            <img class="uimg b" :src="imgurl" alt="" />
          </div>
          <div class="uname l-start">
            <div class="name l-nowraptxt">
              {{ loginUserInfo.realName ? loginUserInfo.realName : "用户" + loginUserInfo.mobile }}
            </div>
            <i class="el-icon-caret-top dian dian1"></i>
            <i class="el-icon-caret-bottom dian dian2"></i>
          </div>
          <div class="fw">
            <img class="fwimg" src="@/assets/img2024/service/s10.png" />
            <div class="fwbox">
              <div class="frow b l-start" v-for="(itm, index) in fwList" @click="navHandle(itm)" v-show="ishome || itm.id == 2">
                <img class="fimg" :src="itm.img" alt="" />
                {{ itm.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <msg @closeFn="closeFn" v-if="ismsgBox"></msg>
    <!-- <div class='twonav'>
        <div class='twonav-box l-between'>
            <div class='twonavlist' v-for='(itm,index) in navtwoList'>

            </div>
        </div>
    </div> -->
    <div class="redts" v-if="isredts">
      本平台为互联网非涉密平台，严禁处理、传输国家秘密
    </div>
  </div>
</template>

<script>
import { getNodeListWithSelectInfoAPI, queryListAPI } from "@/api/common";
import msg from "./msg.vue";
import params from "@/utils/params.js";
import { mapGetters } from "vuex";
import { logoutAPI } from "@/api/user";
import { toLogin, getToken } from "@/utils/auth";
export default {
  name: "Header",
  components: {
    msg
  },
  data() {
    return {
      isredts:true,
      isdialog: false,
      islogin: false,
      isshow: false,
      ismsg: 0,
      vueAppType: process.env.VUE_APP_APPTYPE,
      ismsgBox: false,
      routePath: "",
      headcls: true,
      fwList: [
        { id: 1, title: "企业中心", url: "/home/index", img: require("@/assets/img/h17.png") },
        { id: 2, title: "退出", url: "/service", img: require("@/assets/img/h18.png") }
      ],
      jxList: [
        { title: "标识", url: "/analyze" },
        { title: "MA编码", url: "/analyze" },
        { title: "MA编码体系", url: "/analyze" },
        { title: "标识解析价值", url: "/analyze" }
      ],

      isnews: [],
      options: [],
      value: "",
      use: {},
      istoken: true,
      allnode: []
    };
  },
  computed: {
    ...mapGetters(["loginUserInfo", "msgNum", "globalConfig"]),
    nodeItm() {
      let obj = {};
      this.allnode.map(res => {
        if (this.loginUserInfo && res.id == this.loginUserInfo.nodeId) {
          obj = res;
        }
      });
      return obj;
    },
    ishome() {
      let k = true;
      if (
        this.loginUserInfo.nodeId == this.value ||
        this.loginUserInfo.isAuth == -1 ||
        this.loginUserInfo.isAuth == 4 ||
        this.loginUserInfo.firmId == 0 ||
        !this.loginUserInfo.firmId
      ) {
        k = true;
      } else {
        k = false;
      }
      return k;
    },
    imgurl() {
      let t = require("@/assets/img/h16.png");
      return this.loginUserInfo.userImg ? process.env.VUE_APP_API_BASE_URL + "/adminFile/down/" + this.loginUserInfo.userImg : t;
    }
  },
  watch: {
    $route(v) {
      this.isredts = v.path.indexOf('home')>-1? true : false;
      this.routePath = v.path;
    },
    msgNum(newVal) {
      this.getmsg();
    }
  },
  created() {},
  mounted() {
    this.routePath = this.$route.path;
    if (!getToken()) {
      this.istoken = false;
    }
    if (this.istoken) {
      this.$store.dispatch("getUserInfo");
      this.getmsg();
    }

    this.getnode();
    console.log(this.loginUserInfo, "---------111");
  },
  methods: {
    getmsg() {
      let o = {
        pageNum: 1,
        pageSize: 10,
        isRead: 0
      };

      // if(!this.loginUserInfo.mobile) return
      queryListAPI(o).then(res => {
        if (res.code == 0) {
          this.ismsg = res.data.list.length;
        }
      });
    },
    msgFn() {
      // if (!this.istoken) {
      //   toLogin("登录");
      //   return;
      // }
      this.ismsgBox = !this.ismsgBox;
    },

    seleChange(val) {
      // sessionStorage.setItem('nodeValue',val)
      this.options.map(itm => {
        if (val == itm.id) {
          let k = getToken() ? "?token=" + getToken() : "";
          // dataWeightDetermination_token_city 城市
          // dataWeightDetermination_token_country 国家
          window.location.href = itm.webUrl + k;
        }
      });
    },

    getnode() {
      getNodeListWithSelectInfoAPI()
        .then(res => {
          if (res.code == 0) {
            this.options = [];
            this.allnode = res.data;
            res.data.map(itm => {
              if (itm.nodeExtras.isRedirectNode) this.options.push(itm);
              if (itm.select) {
                this.value = itm.id;
                document.title = itm.name;

                sessionStorage.setItem("nodeValue", JSON.stringify(itm));
              }
            });

            //  let  nodeValue = sessionStorage.getItem('nodeValue')

            //  if(nodeValue){
            //     this.value=nodeValue*1
            //  }else{
            //  }
          } else {
          }
        })
        .catch(error => {});
    },
    closeFn() {
      this.getmsg();
      this.ismsgBox = false;
    },
    goHref: toLogin,
    navHandle(item) {
      // document.title = item.title;
      if (item.title == "退出") {
        this.$store.dispatch("logout");
        return;
      }

      if (item.title == "企业中心" || (item.url == "/flow2" && this.loginUserInfo.userId)) {
        if (!this.ishome && item.url == "/flow2") {
          console.log(this.nodeItm);
          let txt = "请前往您所在的<strong style='color:#409EFF'> " + this.nodeItm.name + " </strong>进行登记。";
          this.$alert(txt, "提示", {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "知道了",
            center: true,
            callback: action => {}
          });
          return;
        }
        window.location.href = `${process.env.VUE_APP_ADMINPATH}/#/`;
        return;
      }

      this.$router.push({
        path: item.url,
        query: { title: item.title }
      });
    },
    getList(id) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        titleType: id
      };
      // selectReceptionTitle(data)
      //   .then(res => {
      //     if (res.code == 0) {
      //       for(let i=0;i<res.data.records.length;i++){
      //         this.isnews.push(res.data.records[i])
      //       }

      //     } else {
      //     }
      //   })
      //   .catch(error => {});
    }
  }
};
</script>

<style lang="less" scoped>
.header-wrap {
  background: rgba(32, 22, 79, 1);
  // background: rgba(8,15,32,.8);
  height: 70px;
  position: fixed;
  z-index: 99;
  width: 100%;
}
.header {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  min-width: 1200px;
}
.logo-wrap {
  display: flex;
  align-items: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  cursor: pointer;
  margin-left: 30px;
  // position: absolute;
  // left: 34px;
  // top:0;
  .txtbox {
    margin-top: 5px;
  }
  /deep/.el-input__inner {
    border: none;
    text-align: left;
    width: 130px;
  }
  img {
    width: 322px;
    display: block;
  }
  .logo-line {
    width: 1px;
    height: 27px;
    background: #fff;
    margin: 0 10px;
  }
  .xian {
    width: 1px;
    height: 36px;
    background: #d5dde5;
    margin: 5px 7px 0 30px;
  }
  .txt {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #23282d;
    line-height: 19px;
    text-align: left;
    font-style: normal;
  }
}
.rigbox {
  // position: absolute;
  // top:0;
  // right: 34px;

  margin: 0 34px;
  .use {
    position: relative;
    .ubox {
      width: 34px;
      height: 34px;
      border-radius: 19px;
      border: 1px solid #4d71f2;
      text-align: center;
      margin-top: 18px;
      .uimg {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-top: 1px;
      }
    }
    .uname {
      margin: 24px 0 0 7px;
      .name {
        height: 19px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #23282d;
        line-height: 19px;
        text-align: left;
        font-style: normal;
      }
      .dian {
        color: #c9ced7;
        position: relative;
        top: 2px;
        display: none;
      }
      .dian1 {
        display: block;
      }
    }
    &:hover {
      .fw {
        display: block;
      }
      .dian1 {
        display: none;
      }
      .dian2 {
        display: block;
      }
    }
    .fw {
      display: none;
      position: absolute;
      top: 60px;
      left: 50%;
      width: 174px;
      margin-left: -87px;

      .fwimg {
        position: relative;
        left: 120px;
        z-index: 10;
        top: 6px;
      }
      .fwbox {
        background: #ffffff;
        position: relative;
        padding: 10px 0;
        border-radius: 5px;
        width: 158px;
        background: #ffffff;
        box-shadow: 0px 2px 7px 3px rgba(86, 86, 86, 0.13);
      }
      .frow {
        width: 158px;
        height: 43px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        line-height: 43px;
        text-align: center;
        font-style: normal;
        .fimg {
          width: 23px;
          height: 23px;
          margin: 10px 8px 0 10px;
        }
        &:hover {
          background: #f5f8ff;
          color: #2165fc;
        }
      }
    }
  }
}
.hbtn {
  height: 34px;
  line-height: 10px;
  margin-top: 16px;
}
.himg {
  width: 34px;
  height: 34px;
  margin: 18px 20px 0 0;
}
.w1180 {
  position: absolute;
  width: 1180px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.nav-wrap {
  display: flex;
  align-items: center;

  .nav-item {
    margin-right: 82px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      .subnav-wrap {
        display: block;
      }
    }
  }
  // &:hover{
  //   .fw{
  //     display: block;
  //   }
  // }

  .nav-name {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
    &.active {
      color: #4478e7;
    }
  }
}
.subnav-wrap {
  z-index: 1;
  position: absolute;
  width: 100px;
  height: 65px;
  top: 5px;
  left: -20px;
  display: none;
  cursor: pointer;
}
.subnav {
  cursor: auto;
  width: 100vw;
  position: fixed;
  top: 65px;
  left: 0;
  transform: translate(0, 0);
  display: flex;
  justify-content: center;
  // background: rgba(0, 0, 1, 0.9);
  // background: rgba(255, 255, 255, 0.95);
  background: #fff;
  font-size: 16px;
  // color: rgba(255, 255, 255, 0.8);
  color: #25365c;
  padding: 18px 12px 0 22px;
  white-space: nowrap;
  padding-bottom: 20px;
  box-shadow: 0 8px 13px 0 rgba(122, 148, 204, 0.3);
  border-radius: 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);

  &::before {
    // content: "";
    width: 0;
    height: 0;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent rgba(255, 255, 255, 0.9);
    position: absolute;
    top: -10px;
    left: 144px;
  }
  ul {
    margin-right: 55px;
    width: 1180px;
    &:last-child {
      margin-right: 0;
    }
  }
  h3 {
    border-bottom: 1px solid #e4e8f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #fff;
  }
  li {
    // margin-bottom: 10px;
    font-size: 14px;
    line-height: 30px;
    // color: #25345c;
    color: #666666;
    background: url(../../assets/img/footer_2.png) no-repeat left center;
    background-size: 13px 13px;
    padding-left: 18px;
    cursor: pointer;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/footer_3.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
}

.headcls {
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  .logo-wrap {
    span {
      background-image: linear-gradient(105deg, #4e8cbd 0%, #bc7dba 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
    .logo-line {
      background: rgba(31, 27, 83, 0.22);
    }
  }
  .nav-name {
    color: #183967;
    &:hover {
      color: #999;
    }
  }
}
.rig-d {
  position: fixed;
  right: 11px;
  top: 50%;
  z-index: 2000;
  border-radius: 4px;
  text-align: center;
  width: 80px;
  height: 183px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 9px rgba(65, 127, 245, 0.13);
  border-radius: 43px;
  padding-top: 39px;
  .box {
    width: 100%;
    height: 100%;
  }
  .img {
  }
  .txt {
    width: 19px;
    height: 84px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #326ffd;
    line-height: 20px;
    padding-top: 4px;
    margin: 0 auto;
  }
  .leftbox {
    position: absolute;
    right: 90px;
    top: 0;
    width: 244px;
    height: 433px;
    box-shadow: 0px 0px 14px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .leftboxd {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .leftx {
      position: absolute;
      top: 6px;
      right: 12px;
      width: 25px;
      height: 30px;
    }
    .img2 {
      width: 244px;
    }
    .img3 {
      position: absolute;
      top: 174px;
      left: 73px;
      width: 100px;
    }
  }
}
.redts{
  position: absolute;
  width: 100%;
  height: 60px;
  background:rgba(229, 221, 242,.9);
  top: 70px;
  left: 0;
  line-height: 60px;
  z-index: 99;
  text-align: center;
  color:#E92F0F;
  font-size: 30px;
  font-weight: bold;
}
</style>
