<template>
  <div class="publicity">
    <div class="h3">{{ datas.dataName }}</div>
    <div class="l-hbtn-blue btns">公示中</div>

    <div class="box">
      <div style="font-size: 14px; margin: 10px 0 0 0; color: #666">
        公示日期: {{ timesFn(datas.openingTime) + " 至 " + timesFn(datas.closingTime) }}
      </div>

      <div class="l-start h4" style="margin-top: 38px">
        <div class="xian"></div>
        登记主体信息
      </div>

      <div class="cont">
        <el-descriptions class="margin-top" title="" :column="1" border labelClassName="labels">
          <el-descriptions-item v-for="itm in list1" :key="itm.key">
            <template slot="label">
              <!-- <i class="el-icon-user"></i> -->
              {{ itm.label }}
            </template>
            <span v-if="!itm.keyArr && datas[itm.key]"> {{ datas[itm.key] + (itm.key2 ? " 至 " + datas[itm.key2] : "") }}</span>
            <span v-if="itm.keyArr && itm.keyArr.length && datas[itm.key]">
              <span v-for="item in itm.keyArr">{{ datas[item] + " " }}</span>
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="l-start h4" style="margin-top: 56px">
        <div class="xian"></div>
        数据详情
      </div>
      <el-tabs v-model="activeName" @tab-click="handleTabClick" style="margin-bottom: 10px">
        <el-tab-pane label="数据要素介绍" name="1"></el-tab-pane>
        <el-tab-pane label="数据要素详情" name="2"></el-tab-pane>
        <el-tab-pane label="数据要素质量报告" name="3"></el-tab-pane>
      </el-tabs>
      <div>
        <div class="cont" v-show="activeName === '1'">
          <el-descriptions class="margin-top" title="" :column="1" border labelClassName="labels">
            <el-descriptions-item v-for="itm in list2" :key="itm.key">
              <template slot="label">
                <!-- <i class="el-icon-user"></i> -->
                {{ itm.label }}
              </template>
              {{ txtFn(itm) }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <DataElementDetails v-if="activeName === '2'" :baseInfo="datas" :rowData="datas" />
      <DataElementQualityReporting v-if="activeName === '3'" :baseInfo="datas" />
    </div>
    <div class="btnbox l-start">
      <div class="l-hbtn-blue b" @click="showFn">提出异议</div>
      <div class="l-hbtn b common-light-btn" @click="goback">返回列表</div>
    </div>
    <publicityDiaolog @closeFn="closeFn" :datas="datas" v-if="isdialog" :dataId="dataId"></publicityDiaolog>
  </div>
</template>

<script>
import publicityDiaolog from "@/views/components/publicityDiaolog";
import { queryDataAnnounByIdAPI } from "@/api/common";
import DataElementDetails from "@/views/view2024/dataPresentation/components/DataElementDetails.vue";
import DataElementQualityReporting from "@/views/view2024/dataPresentation/components/DataElementQualityReporting.vue";

export default {
  components: {
    publicityDiaolog,
    DataElementDetails,
    DataElementQualityReporting
  },
  props: [],
  data() {
    return {
      dataId: "",
      isdialog: false,
      activeName: "1",
      datas: {},
      list1: [
        { label: "登记主体", key: "firmName" },
        { label: "社会统一信用代码", key: "socialCreditCode" },
        { label: "注册地", key: "registAddr" }
        // { label: "公示时间", key: "openingTime", key2: "closingTime" }
      ],
      list2: [
        // { label: "数据类型", key: "dataTypeName" },
        {
          label: "应用行业",
          key: "industryClassDName"
          //     ,keyArr:['industryClassAName','industryClassBName','industryClassCName',
          // 'industryClassDName']
        },
        { label: "数据起止时间", key: "startDate", key2: "endDate" },
        { label: "数据量", key: "drs" },
        { label: "数据要素描述", key: "description" }
      ]
    };
  },
  watch: {},

  mounted() {
    // let  u= sessionStorage.getItem("dataDetails")
    //  if(u){
    //      this.datas=JSON.parse(u)
    //  }
    this.dataId = this.$route.query.id;
    this.getData();
  },
  methods: {
    handleTabClick() {
      window.scrollTo(0, 0, "smooth");
    },
    timesFn(n) {
      if (!n) return;
      return n.substring(0, 10);
    },
    formatNumberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    txtFn(itm) {
      let t = this.datas[itm.key];
      if (!t) return;
      if (itm.key2) {
        t = this.datas[itm.key] + " 至 " + this.datas[itm.key2];
      }
      // else if(itm.keyArr){
      //     let arr =[]
      //     for(let i= 0;i<itm.keyArr.length;i++){
      //         arr.push(this.datas[itm.keyArr])
      //     }
      //     t= arr.join('/')
      // }
      if (itm.key == "drs" && this.datas.dataType==0) {
        t = this.formatNumberWithCommas(t) + " DRs";
      }
      return t;
    },
    goback(itm, key) {
      this.$router.push({
        path: "/publicity",
        query: {}
      });
      // window.history.back();
    },
    showFn() {
      this.isdialog = true;
    },
    closeFn() {
      this.isdialog = false;
    },
    getData() {
      queryDataAnnounByIdAPI(this.dataId)
        .then(res => {
          if (res.code == 0) {
            this.list2[2].label=    res.data.dataType== 2 ? "字节数" : "数据量";
            this.datas = res.data;
            console.log(res.data,'99999999');
            this.datas.dataId = this.dataId;
           
          } else {
          }
        })
        .catch(error => {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.publicity {
  width: 1148px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 130px;
  .h3 {
    font-family: MicrosoftYaHei;
    font-size: 30px;
    color: #1d2538;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    padding-top: 42px;
    padding-right: 100px;
  }
  .btns {
    width: 99px;
    height: 38px;
    border-radius: 4px;
    line-height: 38px;
    font-size: 14px;
    position: absolute;
    top: 125px;
    right: 0;
  }
  /deep/.labels {
    width: 146px;
  }
  .box {
    .h4 {
      height: 21px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 16px;
      color: #465163;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      margin-bottom: 13px;
    }
    .xian {
      width: 6px;
      height: 14px;
      background: linear-gradient(180deg, #4369e5 0%, #40d9ff 100%);
      border-radius: 3px;
      margin-right: 5px;
      margin-top: 4px;
    }
  }
  .btnbox {
    margin-top: 35px;
    div {
      margin-right: 10px;
    }
  }
}
</style>
