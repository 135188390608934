<template>
  <div class="home">
    <div class="banner">
      <!-- <img src="../assets/img/h_1.png" alt="" /> -->
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="max-height: 600px" v-for="(itm, index) in bannerTop" :key="index">
            <!-- bannerTop -->
            <img style="width: 100%" :src="imgFn(itm.bannerImg)" alt="" />
            <!-- <div class="w1225 banbox" >
                  <img class="h1" src="../assets/img/h_1.png" alt="" />
                  <div class="h2" >让数据成为真正的要素</div>
            </div> -->
          </div>
        </div>
        <!-- <div class="swiper-button-next"></div> -->
        <!-- <div class="swiper-button-prev"></div> -->
        <div class="swiper-pagination"></div>
      </div>
      <div class="w1225">
        <div class="bannerbom" v-if="isCity">
          <div class="btop l-between">
            <img class="bh1x" src="../assets/img/h7.png" alt="" />
            <img class="bh1t" src="../assets/img/h6.png" alt="" />
            <div class="bh1">城市节点概况</div>
            <img class="bh1t" src="../assets/img/h6.png" alt="" />
            <img class="bh1x" src="../assets/img/h5.png" alt="" />
          </div>
          <div class="blist l-between">
            <div class="bblock l-start" v-for="(itm, index) in bNavList" :key="index">
              <img class="bimg" :src="itm.img" alt="" />
              <div class="brig">
                <div class="l-start">
                  <div class="num">{{ nodeVO[itm.key] }}</div>
                  <div class="unit">{{ itm.unitkey?nodeVO[itm.unitkey]:itm.unit }}</div>
                </div>
                <div class="btxt">{{ itm.title }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="bannerbom" v-if="!isCity">
          <div class="btop l-between">
            <img class="bh1x" src="../assets/img/h7.png" alt="" />
            <img class="bh1t" src="../assets/img/h6.png" alt="" />
            <div class="bh1">国家节点概况</div>
            <img class="bh1t" src="../assets/img/h6.png" alt="" />
            <img class="bh1x" src="../assets/img/h5.png" alt="" />
          </div>
          <div class="blist l-between">
            <div class="bblock l-start" v-for="(itm, index) in bNavList2" :key="index">
              <img class="bimg" :src="itm.img" alt="" />
              <div class="brig">
                <div class="l-start brigTop">
                  <div class="num">{{ nodeVO[itm.key] }}</div>
                  <div class="unit">{{ itm.unitkey?nodeVO[itm.unitkey]:itm.unit }}</div>
                </div>
                <div class="btxt">{{ itm.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="nav-box" :class="{ 'nav-box-nation': !isCity }">
      <div class="l-h3">新闻资讯</div>
      <div class="lh2">NEWS</div>

      <div class="nlist">
        <div class="nblock b l-between" v-for="(itm, index) in newsList" :key="index" @click="goNewFn(itm)">
          <div class="nleft">
            <div class="nleft-top l-between">
              <div class="year">{{ tiemFn(itm.createTime, "y") }}</div>
              <div class="xian"></div>
            </div>
            <div class="nleft-bom l-start">
              <div class="moth">{{ tiemFn(itm.createTime, "m") }}/</div>
              <div class="day">{{ tiemFn(itm.createTime, "d") }}</div>
            </div>
          </div>
          <div class="ncen">
            <div class="title">{{ itm.titlePrimary }}</div>
            <div class="txt">{{ itm.titleSecondary }}</div>
          </div>
          <div class="nrigbox border-radius-primary"><img class="nrig" :src="imgFn(itm.titlePhoto)" alt="" /></div>
        </div>
      </div>
      <div class="nbtns common-light-btn b l-start" @click="goNewFn">
        <div>查看更多</div>
        <i class="btn-icon el-icon-arrow-right"></i>
      </div>
    </div> -->
    <!-- v-if="isCity" -->
    <div class="flow">
      <div class="l-h3">登记流程展示</div>
      <div class="lh2">Process</div>
      <div class="fbox l-between">
        <div class="fblock" v-for="(itm, index) in flowList" :key="index">
          <div class="num">{{ itm.num }}</div>
          <div class="title">{{ itm.title }}</div>
          <img class="fimg" :src="itm.img" alt="" />
          <img class="fimgbg" src="../assets/img/hf7.png" alt="" />
        </div>
      </div>
      <div class="nbtns bluebtn common-light-btn b l-start" @click="goflowFn">
        <div>前往登记</div>
        <!-- <img class="nimg" src="../assets/img/h8.png" alt="" /> -->
        <i class="btn-icon el-icon-arrow-right"></i>
      </div>
    </div>

    <!-- <div class="mall" v-if="!isCity">
      <div class="mbox l-between">
        <img class="mimg" src="../assets/img/h14.png" alt="" />
        <div class="mrig">
          <div class="l-h3" style="text-align: left">数据展示</div>
          <div class="lh2" style="width: 144px; margin-bottom: 36px">flow</div>
          <div class="txt">
            工业互联数据资产交易商城，专为工业数据资产交易而打造。我们提供安全、高效的数据交易平台，助力企业挖掘数据价值，实现数据资产的优化配置。
          </div>
          <div class="txt txt2">在这里，您可以轻松买卖工业数据，开启智能工业新篇章。</div>
          <div class="mbtns l-start b" @click="goFn({ name: '数据展示', url: '/service' })">
            了解更多
            <img class="mimgbtn" src="../assets/img/h15.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->

    <div class="dynamic">
      <div class="l-h3" style="color: #fff">数据动态</div>
      <div class="lh2" style="color: rgba(255, 255, 255, 0.4)">dynamic</div>
      <!-- <img class="dimg-left" src="../assets/img/hs2.png" alt="" />
      <img class="dimg-rig" src="../assets/img/hs3.png" alt="" /> -->
      <div class="dbox">
        <div class="dtable l-column">
          <div class="dhead l-start">
            <div :style="'width:' + itm.width + 'px'" v-for="(itm, index) in dtable" :key="index">
              {{ itm.title }}
            </div>
          </div>
          <div class="dcont">
            <div class="swiper-wrapper ul l-column">
              <div class="swiper-slide" style="height: 54px" v-for="(item, index) in tableData" :key="index">
                <div class="bolck l-start">
                  <div
                    class="txt l-nowraptxt"
                    :style="'width:' + itm.width + 'px'"
                    :class="{ pdl45: idx == 0 }"
                    v-for="(itm, idx) in dtable"
                    :key="idx"
                    :title="item[itm.key]"
                  >
                    {{ itm.key ? item[itm.key] : index / 1 + 1 > 10 ? index / 1 + 1 - 10 : index / 1 + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper mySwiper2" style="width: 1225px; height: 406px">
              
            </div> -->
            <!-- <div class="dlist dlistway">
                <div class="drow" >{{  }}</div>
              </div> -->
          </div>
        </div>

        <!-- <el-table
            :data="tableData"
            stripe
            header-cell-class-name="table-head"
            cell-class-name="rowh"
            style="width: 100%">
            <el-table-column
              prop=""
              label=""
              width="45">
            </el-table-column>
            <el-table-column
              prop="dataName"
              label="数据要素名称"
              width="300">
            </el-table-column>
            <el-table-column
              prop="code"
              label="数据要素标识"
              >
            </el-table-column>
            <el-table-column
              prop="drs"
              width="200"
              label="数据要素总量（万）">
            </el-table-column>
            <el-table-column
              prop="createTime"
              width="200"
              label="登记时间">
            </el-table-column>
          </el-table>
           -->
      </div>
    </div>
    <div class="publicity">
      <div class="l-h3">登记公示</div>
      <div class="lh2">publicity</div>
      <div class="pbox">
        <div class="pblock" v-for="(itm, index) in publicityList" :key="index">
          <div class="title l-nowraptxt" :title="itm.dataName">{{ itm.dataName }}</div>
          <div class="txtbox l-start">
            <!-- <div class="type nowraptxt">登记类型: {{ ["数据要素", "数据产品", "数据资产"][itm.dataType] }}</div> -->
            <div class="name nowraptxt" :title="itm.firmName">登记主体: {{ itm.firmName }}</div>
            <div class="time nowraptxt">
              公示日期: {{ timesFn(itm.publicityStartTime) + " 至 " + timesFn(itm.publicityEndTime) }}
            </div>
          </div>
          <div class="pbtn b" @click="goFn({ name: '公示', url: '/publicityDetails' }, itm)">查看详情</div>
        </div>
        <el-empty description="暂无数据" v-if="!publicityList.length" />
      </div>

      <div class="nbtns common-light-btn b l-start" @click="goFn({ name: '公示', url: '/publicity' })">
        <div>查看更多</div>
        <i class="btn-icon el-icon-arrow-right"></i>
      </div>
    </div>
    <homeDialog @closeFn="closeFn" v-if="isDialog"></homeDialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { isElementNotInViewport } from "../utils/index.js";
import {
  selectTitleAPI,
  queryDataAnnouncementsAPI,
  queryDataDynamicsAPI,
  nodeConditionVOAPI,
  nationConditionVOAPI,
  selectBannerAPI
} from "@/api/common";
import homeDialog from "./components/homeDialog";
import params from "@/utils/params.js";
import { mapGetters } from "vuex";
// import newsimg from "@/assets/img/news_img.png";

export default {
  name: "Home",
  components: {
    homeDialog
  },
  data() {
    return {
      isCity: process.env.VUE_APP_APPTYPE == "国家" ? false : true,
      isDialog: false,
      idx: 0,
      input: "",
      datas: {},
      bNavList: [
        { title: "总数据量", key: "totalDrs", unitkey: "totalDrsUnit", img: require("@/assets/img/h1.png") },
        { title: "年新增数据量", key: "yearTotalDrs", unitkey: "yearTotalDrsUnit", img: require("@/assets/img/h2.png") },
        { title: "总发证数", key: "certTotal", unit: "个", img: require("@/assets/img/h3.png") },
        { title: "年新增证书数", key: "yearCertTotal", unit: "个", img: require("@/assets/img/h4.png") }
      ],
      bNavList2: [
        { title: "城市节点数", key: "nodeTotal", unit: "个", img: require("@/assets/img/h10.png") },
        { title: "注册企业数量", key: "firmsTotal", unit: "家", img: require("@/assets/img/h11.png") },
        { title: "颁发证书数", key: "certiTotal", unit: "个", img: require("@/assets/img/h12.png") },
        { title: "登记数据量", key: "dreTotal", unitkey: "dreTotalUnit", img: require("@/assets/img/h13.png") }
      ],
      newsList: [],
      flowList: [
        { num: "01", title: "企业注册", img: require("@/assets/img/hf1.png") },
        { num: "02", title: "下载登记软件", img: require("@/assets/img/hf2.png") },
        { num: "03", title: "数据登记", img: require("@/assets/img/hf3.png") },
        { num: "04", title: "合规审核", img: require("@/assets/img/hf4.png") },
        { num: "05", title: "登记公示", img: require("@/assets/img/hf5.png") },
        { num: "06", title: "证书颁发", img: require("@/assets/img/hf6.png") }
      ],
      publicityList: [],
      tableData: [],
      nodeVO: {},
      dtable: [
        { title: "序号", width: 45 },
        { title: "数据要素名称", width: 490, key: "dataName" },
        { title: "登记主体", width: 320, key: "firmName" },
        { title: "数据要素总量（万）", width: 160, key: "drs" },
        { title: "所属节点", width: 150, key: "nodeName" },
        { title: "登记时间", width: 190, key: "createTime" }
      ],
      scrollPosition: 0,
      bannerTop: []
    };
  },
  computed: {
    ...mapGetters(["loginUserInfo"])
  },
  mounted() {
    let that = this;
    // this.getNum()

    this.getbanner();
    this.getjiedian();
    this.getGongShi();
    this.getNews();
    this.getShuju();
    this.swiperFn();
    
  },
  methods: {
    timesFn(n) {
      return n.substring(0, 10);
    },
    imgFn(p) {
      let k = require("@/assets/img/news_img.png"); //newsimg;
      return p ? process.env.VUE_APP_API_BASE_URL + "/adminFile/down/" + p : k;
    },
    closeFn() {
      this.isDialog = false;
    },
    // 新闻
    tiemFn(time, type) {
      if (!time) return;
      let t = time.split(" ")[0];
      let arr = t.split("-");
      let d = { y: 0, m: 1, d: 2 };
      return arr[d[type]];
    },
    goNewFn(itm) {
      let data = {};
      let url = "/news";
      if (itm && itm.titleId) {
        url = "/newsDetails";
        data = { titleId: itm.titleId };
      }
      this.$router.push({
        path: url,
        query: data
      });
    },
    getNews() {
      let data = {
        pageNum: 1,
        pageSize: 4,
        titleType: 50
      };
      selectTitleAPI(data)
        .then(res => {
          if (res.code == 0) {
            console.log(res, "--------------");
            this.newsList = res.data.list;
          } else {
          }
        })
        .catch(error => {});
    },
    getGongShi() {
      let data = {
        pageNum: 1,
        pageSize: 5
      };
      queryDataAnnouncementsAPI(data)
        .then(res => {
          if (res.code == 0) {
            this.publicityList = res.data.list;
          } else {
          }
        })
        .catch(error => {});
    },
    getShuju() {
      let data = {
        pageNum: 1,
        pageSize: 10
      };
      queryDataDynamicsAPI(data)
        .then(res => {
          if (res.code == 0) {
            this.tableData = [...res.data.list, ...res.data.list];
            setTimeout(() => {
              this.dataSwiperFn();
            }, 1000);
          } else {
          }
        })
        .catch(error => {});
    },
    async getbanner() {
      let res = await selectBannerAPI();
      this.bannerTop = [];
      for (let i = 0; i < res.data.above.length; i++) {
        if (i < 6) this.bannerTop.push(res.data.above[i]);
      }
    },
    getjiedian() {
      let url = nodeConditionVOAPI; //城市
      if (!this.isCity) url = nationConditionVOAPI; //国家
      url({})
        .then(res => {
          if (res.code == 0) {
            this.nodeVO = res.data;
          } else {
          }
        })
        .catch(error => {});
    },

    seachFn(val) {
      if (val) this.input = val;
      if (!this.input) {
        this.$message.error("查询的标识条件不能为空!");
        return;
      }
      this.$router.push({
        path: "/seach",
        query: { txt: this.input }
      });
    },
    navFn(itm, index) {
      this.idx = index;
    },
    goflowFn() {
      let item = {
        title: "登记流程展示",
        url: "/flow"
      };
      if (this.loginUserInfo.userId) {
        window.location.href = `${process.env.VUE_APP_ADMINPATH}/#/`;
        return;
      }

      this.$router.push({
        path: item.url,
        query: { title: item.title }
      });
    },
    goFn(itm, key) {
      // if (!this.loginUserInfo.userName) {
      //   this.$message.error("请先登录!");
      //   return;
      // }
      document.title = itm.name;
      let data = { title: itm.name };
      let url = itm.url;
      if (itm.url == "/publicityDetails") {
        data.id = key.dataId;
      }
      this.$router.push({
        path: url,
        query: data
      });
    },
    goalFn() {
      this.$router.push({
        path: "/applyCase",
        query: { title: "应用案例" }
      });
    },
    txtFn(num, type) {
      let n = num + "";
      let d = "";
      if (!n || n == "NaN") return type ? 0 : d;
      if (n == "undefined") return type ? 0 : "";

      n = n.split(".")[0];
      if (n.length >= 9) {
        console.log(n);
        d = "亿";
        n = (parseFloat(n) / 100000000).toFixed(1);
      } else if (n.length >= 4) {
        d = "万";
        n = (parseFloat(n) / 10000).toFixed(1);
      } else {
        d = "";
      }

      return type ? n : d;
    },

    productHandle(path) {
      this.$router.push({
        path
      });
    },
    dataSwiperFn() {
      return;
      var swiper = new Swiper(".mySwiper2", {
        direction: "vertical",
        slidesPerView: 8,
        watchSlidesProgress: true,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true
        }
      });
    },
    swiperFn() {
      var swiper = new Swiper(".mySwiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        spaceBetween: 0,
        autoplay: {
          delay: 4000
        },
        // loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.home {
  background: #fff;
}
.w1225 {
  width: 1225px;
  margin: 0 auto;
}
.nbtns {
  width: 195px;
  // background: #f1f6ff;
  border-radius: 8px;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #8e939f;
  // line-height: 65px;
  // letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  transition: all 0.5s;
  .btn-icon {
    margin-left: 10px;
  }
}

//banner
.banner {
  user-select: none;
  width: 100%;
  position: relative;
  background: #f5f7fa;
  min-height: 100px;
  // height:602px;
  // min-height: 602px;
  // background: url("../assets/img/bg.png") no-repeat center top;
  // background-size: 1920 602px;
  margin-bottom: 178px;
  :deep(img) {
    display: block;
  }
  .banbox {
    position: absolute;
    top: 30%;
    left: 50%;
    margin-left: -612px;
  }
  .h1 {
    display: block;
  }
  .h2 {
    width: 602px;
    height: 61px;
    background: linear-gradient(264deg, rgba(13, 181, 254, 0) 0%, #3a8ae7 100%);
    border-left: 5px solid #4df491;
    font-family: MicrosoftYaHei;
    font-size: 26px;
    color: #ffffff;
    line-height: 61px;
    letter-spacing: 3px;
    text-align: left;
    font-style: normal;
    padding-left: 14px;
    margin-top: 40px;
  }
  .bannerbom {
    width: 1225px;
    height: 206px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 20px 16px rgba(11, 22, 55, 0.05);
    border-radius: 8px;
    border: 1px solid #ffffff;
    backdrop-filter: blur(37px);
    position: absolute;
    bottom: -103px;
    margin: 0 auto;
    z-index: 10;
    .btop {
      padding: 29px 40px 0 40px;
      .bh1 {
        min-width: 160px;
        height: 31px;
        font-family: MicrosoftYaHei;
        font-size: 24px;
        color: #3a444d;
        line-height: 31px;
        text-align: center;
        font-style: normal;
      }
      .bh1x {
        width: 482px;
        height: 2px;
        position: relative;
        top: 14px;
      }
      .bh1t {
        width: 15px;
        height: 15px;
        position: relative;
        top: 8px;
      }
    }

    .blist {
      margin-top: 40px;
      padding: 0 45px;
      .bblock {
        .bimg {
          width: 57px;
          height: 57px;
          margin-right: 19px;
          margin-top: 6px;
        }
        &:hover {
          .brig {
            .num {
              transform: scale(1.1);
            }
          }
        }
        .brig {
          .num {
            transition: all linear 0.1s;
            height: 46px;
            font-family: AvenirNextCondensed, AvenirNextCondensed;
            font-weight: 500;
            font-size: 32px;
            color: #2f354d;
            line-height: 46px;
            text-align: left;
            font-style: normal;
          }
          .unit {
            height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #3b4358;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            margin-left: 5px;
            margin-top: 20px;
          }
        }
        .btxt {
          height: 19px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #717d98;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}
.nav-box-nation {
  // background: linear-gradient(rgba(13, 181, 254, 0) 0%, rgba(217, 240, 254, 0.2) 80%, rgba(217, 240, 254, 0.3) 100%);
  padding-bottom: 1px;
  // .nbtns {
  //   transition: all 0.5s;
  //   background-color: #fff;
  //   border: 1px solid #f0f0f0;
  //   &:hover {
  //     border-color: #2165fc;
  //   }
  // }
}
.nav-box {
  .nlist {
    width: 1202px;
    margin: 0 auto;
    .nblock {
      border-bottom: 1px solid #dfe7f1af;
      width: 100%;
      height: 150px;
      padding: 28px 0;
      .nleft {
        width: 150px;
        margin-right: 10px;
        .year {
          height: 28px;
          font-weight: 500;
          font-size: 16px;
          color: #68728b;
          line-height: 28px;
          text-align: left;
          font-style: normal;
        }
        .xian {
          width: 64px;
          height: 1px;
          background: #c3ceda69;
          position: relative;
          top: 12px;
        }
        .moth,
        .day {
          height: 25px;
          font-weight: 500;
          font-size: 20px;
          color: #2f354d;
          line-height: 25px;
          text-align: left;
          font-style: normal;
        }
        .day {
          color: #47cdf8;
        }
      }
      .ncen {
        margin-right: 57px;
        .title {
          width: 830px;
          height: 26px;
          font-family: MicrosoftYaHei;
          font-size: 18px;
          color: #2f354d;
          line-height: 26px;
          letter-spacing: 2px;
          text-align: left;
          font-style: normal;
        }
        .txt {
          width: 785px;
          height: 46px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #a7a9b4;
          line-height: 23px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
          margin-top: 18px;
        }
      }
      .nrigbox {
        width: 154px;
        height: 91px;
        overflow: hidden;
      }
      .nrig {
        width: 154px;
        height: 91px;
        transition: all 200ms linear;
      }
      &:hover {
        .title {
          color: #2165fc;
        }
        .nrig {
          transform: scale(1.2);
        }
      }
    }
  }
}

.flow {
  padding-top: 54px;
  width: 100%;
  height: 518px;
  background: url(../assets/img/h9.png) no-repeat bottom center;
  background-size: 1922px 518px;
  .fbox {
    width: 1224px;
    margin: 0 auto;
    margin-top: 28px;
    .fblock {
      width: 184px;
      height: 174px;
      border-radius: 8px;
      border: 1px solid #ffffff;
      position: relative;
      padding: 28px 16px;
      box-shadow: 0px 0px 18px 14px rgba(21, 41, 93, 0.04);
      transition: all linear 0.1s;
      background: rgba(255, 255, 255, 0.2); /* 半透明背景 */
      backdrop-filter: blur(20px); /* 毛玻璃效果 */
      -webkit-backdrop-filter: blur(10px); /* 兼容Safari */
      &:hover {
        transform: scale(1.1);
      }
      .num {
        height: 36px;
        font-family: AvenirNextCondensed, AvenirNextCondensed;
        font-weight: 500;
        font-size: 26px;
        color: #2f354d;
        line-height: 36px;
        text-align: left;
        font-style: normal;
        position: relative;
        z-index: 2;
      }
      .title {
        height: 21px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: 500;
        font-size: 16px;
        color: #2f354d;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        position: absolute;
        left: 16px;
        top: 111px;
        z-index: 2;
      }
      .fimg {
        width: 47px;
        height: 47px;
        position: absolute;
        top: 23px;
        right: 10px;
        z-index: 2;
      }
      .fimgbg {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0.45;
      }
    }
  }
}

.mall {
  width: 100%;
  height: 555px;
  background: url(../assets/img/h9.png) no-repeat bottom center;
  background-size: 1922px 555px;
  .mbox {
    width: 1202px;
    margin: 0 auto;
    .mimg {
      width: 487px;
      height: 494px;
      margin: 29px 71px 0 0;
    }
    .mrig {
      width: 696px;
      margin-top: 82px;
      .txt {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #6e7585;
        line-height: 34px;
        text-align: left;
        font-style: normal;
      }
      .txt2 {
        margin-top: 29px;
      }
      .mbtns {
        width: 137px;
        height: 53px;
        background: linear-gradient(270deg, #45b0fe 0%, #31abfd 49%, #2165fc 100%);
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ffffff;
        line-height: 53px;
        text-align: center;
        font-style: normal;
        padding-left: 22px;
        margin-top: 77px;
        .mimgbtn {
          width: 18px;
          height: 18px;
          margin: 18px 0 0 13px;
        }
      }
    }
  }
}

.publicity {
  padding-bottom: 50px;
  width: 100%;
  padding-top: 10px;
  .pbox {
    width: 1227px;
    margin: 0 auto;
    margin-top: 30px;

    .pblock {
      width: 1227px;
      height: 116px;
      background: #ffffff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
      transition: box-shadow 0.3s;
      // box-shadow: 0px 0px 18px 14px rgba(19, 40, 64, 0.04);
      &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      padding: 22px 24px;
      margin-bottom: 12px;
      position: relative;
      .title {
        height: 28px;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #2f354d;
        line-height: 28px;
        text-align: left;
        font-style: normal;
      }
      .txtbox {
        margin-top: 13px;
        color: #82869d;
        .type {
          width: 160px;
          margin-right: 20px;
        }
        .name {
          width: 460px;
          margin-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .time {
          width: 530px;
        }
      }
      .pbtn {
        width: 92px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #2e6bff;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #2e6bff;
        line-height: 36px;
        letter-spacing: 1px;
        text-align: center;
        font-style: normal;
        position: absolute;
        right: 31px;
        top: 40px;
      }
    }
  }
}

.dynamic {
  padding-top: 54px;
  width: 100%;
  height: 720px;
  background: url(../assets/img/hs1.png) no-repeat top center;
  background-size: 1921px 678px;
  position: relative;
  .dimg-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .dimg-rig {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .dbox {
    position: relative;
    z-index: 2;
    width: 1225px;
    background: #ffffff;
    box-shadow: 0px 0px 18px 14px rgba(19, 40, 64, 0.04);
    border-radius: 8px;
    margin: 29px auto;
    /deep/.table-head {
      background: #575d6a;
      color: #fff;
      height: 50px;
      line-height: 50px;
      padding: 0 !important;
    }
    /deep/.el-table {
      border-radius: 8px;
    }
    /deep/.rowh {
      height: 54px;
      line-height: 54px;
    }
  }
  .dtable {
    width: 1225px;
    height: 456px;
    // background: #ffffff;
    box-shadow: 0px 0px 18px 14px rgba(19, 40, 64, 0.04);
    border-radius: 6px;
    overflow: hidden;
    .dhead {
      width: 100%;
      height: 50px;
      background: #0f41a7;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      text-align: left;
      font-style: normal;
      line-height: 50px;
      padding-left: 45px;
    }
    .dcont {
      position: relative;
      flex: 1;
      background: #fff;
      .dlist {
      }
      .dlistway {
        transform: translateY(100);
      }
      .txt {
        padding: 0 5px 0 0;
      }
    }

    .bolck {
      .txt {
        height: 54px;

        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #3d4453;
        line-height: 54px;
        text-align: left;
        font-style: normal;
      }
      .pdl45 {
        margin-left: 45px;
      }
    }
    :deep(.swiper-wrapper) {
      > :nth-child(odd) {
        background: #fff !important;
      }
      > :nth-child(even) {
        background: #f6faff !important;
      }
    }
  }
}
.lh2 {
  width: 100%;
  height: 26px;
  font-weight: 300;
  font-size: 16px;
  color: #b7b9bd;
  line-height: 26px;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 5px;
  text-align: center;
}

.swiper-pagination {
  bottom: 110px;
}
.bluebtn {
  color: #2e6bff !important;
  border: 1px solid #2e6bff !important;
}
.bluebtn:hover {
  color: #fff !important;
  border: 1px solid #2e6bff !important;
  background: #2e6bff;
}

.dcont {
  overflow: hidden;
}
.ul {
  width: 1225px;
  height: 1080px;
  overflow: hidden;
  position: relative;
}

@keyframes scroll {
  0% {
    top: 0;
  }
  100% {
    top: -540px; /* 根据实际高度调整 */
  }
}

.ul {
  animation: scroll 15s linear infinite;
}
</style>

<style lang="less" scoped></style>
